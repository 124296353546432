<template>
    <div class="sharing-page">
        <div class="page-title">分账</div>

        <div class="self-info">
            <div class=" member-card">
                <img :src="orderInfo.member.avatar" alt="">
                <span class="member-name">{{ orderInfo.member.mark_name || orderInfo.member.name }}</span>
                <span class="member-id">
                    <span>ID</span>
                    {{ orderInfo.member.member_id }}
                </span>
                <span class="member-phone">
                    <span>手机号</span>
                    {{ orderInfo.member.phone || '未填写' }}
                </span>

                <a-popover placement="bottom" trigger="click" v-if="enabledEdit">
                    <template slot="content">
                        <div class="member-search">
                            <div class="search-input-container">
                                <at-input v-model="memberKeywords" style="width: 200px">
                                    <div @click="searchMember" class="search-btn"></div>
                                </at-input>

                                <div class="member-list">
                                    <template v-for="(item,mk) in memberList">

                                        <div :key="mk" class="member-item">

                                            <div class=" member-card"
                                                 @click="updateOrderMember(item)">
                                                <img :src="item.avatar" alt="">
                                                <span class="member-name">{{ item.name }}</span>
                                            </div>

                                        </div>

                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="white-edit-box">
                        <span class="white-edit"></span>
                    </div>

                </a-popover>
            </div>
            <div class="line"></div>
        </div>

        <a-card class="sharing-card" title="推荐关系情况">

            <recommend :member="orderInfo.member"
                       :yc-parents="orderInfo.yc_parents"
                       :ast-parents="orderInfo.ast_parents"
            />

        </a-card>

        <a-card class="sharing-card" title="订单关联情况">
            <div class="tj-group mb-4">
                <div class="group-title">服务搭配</div>
            </div>
            <div class="admin-list group-content self-info">
                <template v-for="(admin,ak) in orderInfo.sales">
                    <div :key="ak" class="member-card">
                        <img :src="admin.avatar" alt="">
                        <span class="member-name">{{ admin.mark_name || admin.name }}</span>
                    </div>
                </template>
            </div>
        </a-card>

        <a-card class="sharing-card" title="订单信息">
            <div v-if="orderInfo.is_first" class="first-order"></div>
            <div class="order-info" v-if="orderInfo.id">
                <table class="order-info-table">
                    <tr>
                        <th>支付方式</th>
                        <th>订单金额</th>
                        <th>优惠金额</th>
                        <th>到账（扣除手续费）</th>
                        <th>记账抵扣金额</th>

                    </tr>
                    <tr>
                        <td>
                            <template v-if="orderInfo.pay_method === 'OFFLINE' ">
                                线下 - {{ offlinePayMethods[orderInfo.offline_pay_method] }}
                            </template>
                            <template v-else-if="orderInfo.pay_method  === 'BALANCE' ">记账抵扣</template>
                            <template v-else>微信支付</template>
                        </td>
                        <td>￥{{ orderInfo.amount }}</td>
                        <td>￥{{ orderInfo.coupon_amount }}</td>
                        <td>￥{{ orderInfo.real_amount }}</td>
                        <td>￥{{ orderInfo.balance }}</td>
                    </tr>

                    <tr>
                        <th>冻结天数</th>
                        <th>订单时间</th>
                        <th colspan="4">备注</th>
                    </tr>
                    <tr>
                        <td>{{ orderInfo.sharing !== 1 ? 0 : orderInfo.unlock_day }}</td>
                        <td>{{ formatTime(orderInfo.created_at) }}</td>
                        <td colspan="4">{{ orderInfo.subject }}</td>
                    </tr>
                    <tr v-if=" orderInfo.pay_method === 'OFFLINE' && orderInfo.pay_amount <= 0 ">
                        <td colspan="5">
                            <button @click="activeSharePayModal" class="btn">微信分账支付</button>
                        </td>
                    </tr>
                </table>
            </div>
        </a-card>


        <a-card class="sharing-card" title="代付信息" v-if="orderInfo.share_order">
            <div class="order-info">
                <table class="order-info-table">
                    <tr>
                        <th>支付方式</th>
                        <th>订单金额</th>
                        <th>代付时间</th>
                    </tr>

                    <tr>
                        <td>微信支付</td>
                        <td>￥{{ orderInfo.share_order.amount }}</td>
                        <td>{{ formatTime(orderInfo.share_order.paid_at) }}</td>
                    </tr>
                </table>
            </div>
        </a-card>


        <div class="sh-info">

            <div class="sh-title">分账明细</div>
            <div class="line"></div>
            <div class="sh-total">
                <div>
                    <span class="sh-total-title">订单可分账金额</span>

                    <span class="sh-total-money">
                    <span>￥</span>{{ orderInfo.sharing_amount }}
                </span>
                </div>
                <div>
                    <span class="sh-total-title">总剔除</span>

                    <span class="sh-total-money">
                    <span>￥</span>{{ (orderInfo.real_amount - orderInfo.sharing_amount).toFixed(2) }}
                </span>
                </div>

            </div>

            <div class="sh-list">
                <table class="sh-list-table">

                    <template v-for="(exclude,ek) in orderInfo.excludes">

                        <tr :key="`ek-${ek}`">

                            <td :colspan="  (orderInfo.shares && orderInfo.shares.length) ? 8 : 7 ">
                                <div class="exclude-item">
                                    <div>
                                        <span class="color-999">剔除项目：</span>
                                        <template v-if="exclude.editing">

                                            <a-auto-complete v-model="exclude.memo"
                                                             class="exclude-complete"
                                                             option-label-prop="title"
                                            >
                                                <template slot="dataSource" v-for="str of excHistory ">
                                                    <a-select-option :key="str" :title="str">
                                                        <div class="memo-his-item">
                                                            {{ str }}
                                                            <a @click.stop="delExcHistory(str)" class="del-btn"></a>
                                                        </div>
                                                    </a-select-option>
                                                </template>
                                            </a-auto-complete>
                                        </template>
                                        <template v-else>
                                            {{ exclude.memo }}
                                        </template>
                                    </div>
                                    <div>
                                        <span class="color-999">金额：</span>
                                        <span>￥</span>
                                        <template v-if="exclude.editing">
                                            <input v-model="exclude.amount" type="text" class="sharing-input">
                                        </template>
                                        <template v-else>
                                            {{ exclude.amount }}
                                        </template>
                                    </div>
                                </div>
                            </td>
                            <td v-if="!(orderInfo.shares && orderInfo.shares.length)">
                                <template v-if="exclude.editing">
                                    <span @click=" addExcludeCancel(exclude) " class="black-cancel mr-20"></span>
                                    <span @click="confirmSyncExclude(exclude)" class="black-confirm"></span>
                                </template>
                                <template v-else-if=" !(!orderInfo.shares) || (!orderInfo.shares.length) ">

                                    <span @click=" editExclude(exclude) " class="white-edit mr-20"></span>
                                    <span @click="deleteExclude(exclude)" class="white-delete"></span>

                                </template>

                            </td>

                        </tr>

                    </template>

                    <tr v-if="(!(orderInfo.shares && orderInfo.shares.length) ) && enabledEdit ">
                        <td colspan="8">
                            <div class="new-sharing" @click="addExclude">
                                <span class="ns-btn"></span>
                                <span>添加剔除项目</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>分账类型</td>
                        <td>用户ID</td>
                        <td>分账用户</td>
                        <td>方式</td>
                        <td>分账比例</td>
                        <td>分配金额</td>
                        <td>状态</td>
                        <td>操作</td>
                    </tr>
                    <template v-for=" (sharing,key) in orderInfo.shares ">
                        <tr :key="key" :class="{'sh-editing-row':sharing.editing}">
                            <td>
                                <template v-if="sharing.editing">
                                    <a-auto-complete v-model="sharing.memo"
                                                     class="sh-auto-complete"
                                                     option-label-prop="title"
                                    >
                                        <template slot="dataSource" v-for="str of memoHistory ">
                                            <a-select-option :key="str" :title="str">
                                                <div class="memo-his-item">
                                                    {{ str }}
                                                    <a @click.stop="delMemoHistrot(str)" class="del-btn"></a>
                                                </div>
                                            </a-select-option>
                                        </template>

                                        <a-input :maxLength="15"/>

                                    </a-auto-complete>
                                </template>
                                <template v-else>
                                    {{ sharing.memo }}
                                </template>

                            </td>
                            <td>{{ sharing.to_member.member_id || '-' }}</td>
                            <td>
                                <div class="arrow-down-input" v-if="sharing.editing">

                                    <a-popover placement="bottom">
                                        <template slot="content">
                                            <div class="member-search">
                                                <div class="search-input-container">
                                                    <at-input v-model="memberKeywords" style="width: 200px">
                                                        <div @click="searchMember" class="search-btn"></div>
                                                    </at-input>

                                                    <div class="member-list">
                                                        <template v-for="(item,mk) in memberList">

                                                            <div :key="mk" class="member-item">

                                                                <div class=" member-card"
                                                                     @click="selectMember(sharing,item)">
                                                                    <img :src="item.avatar" alt="">
                                                                    <span class="member-name">{{
                                                                        item.mark_name || item.name
                                                                        }}</span>
                                                                </div>

                                                            </div>

                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>

                                        <div v-if="!sharing.to_am_id">
                                            请选择收款方
                                        </div>
                                        <div class=" member-card" v-else>
                                            <img :src="sharing.to_member.avatar" alt="">
                                            <span class="member-name">{{
                                                sharing.to_member.mark_name || sharing.to_member.name
                                                }}</span>
                                        </div>

                                    </a-popover>

                                </div>
                                <div class=" member-card" v-else>
                                    <img :src="sharing.to_member.avatar" alt="">
                                    <span class="member-name">{{
                                        sharing.to_member.mark_name || sharing.to_member.name
                                        }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="arrow-down-input" v-if="sharing.editing">
                                    <a-select v-model=" sharing.type " class="sh-select" default-value="SHARING">
                                        <a-select-option value="SHARING">
                                            分账
                                        </a-select-option>
                                        <a-select-option value="OFFLINE">
                                            记账
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <template v-else>
                                    <template v-if="sharing.type === 'SHARING' ">
                                        分账
                                    </template>
                                    <template v-else>
                                        记账
                                    </template>
                                </template>
                            </td>
                            <td>
                                <template v-if="sharing.editing">
                                    <at-input v-model="sharing.share_scale"
                                              @change="sharingShareScaleChanged(sharing)"
                                              style="width: 100px" addon-after="%"
                                              highlight class="sh-input"/>
                                </template>
                                <template v-else>{{ sharing.share_scale }}%</template>

                            </td>
                            <td>
                                ￥{{ sharing.share_amount || '0.00' }}
                            </td>
                            <td>
                                <template v-if="!sharing.id">新增中</template>
                                <template v-else-if="sharing.editing">编辑中</template>
                                <div v-else-if="sharing.state === 'NONE' " @click="confirmSharing(sharing)">
                                    <template v-if="sharing.type === 'SHARING' ">
                                        <button class="btn">分账</button>
                                    </template>
                                    <template v-else>
                                        <button class="btn">记账</button>
                                    </template>
                                </div>
                                <div v-else-if="sharing.state === 'FINISHED' ">
                                    <div class="mb-2">已完成</div>
                                    <div v-if="sharing.finished_at">{{ formatTime(sharing.finished_at) }}</div>
                                </div>
                                <div v-else-if="sharing.state === 'CLOSED' ">
                                    已关闭
                                </div>
                                <div v-else-if="sharing.state === 'PENDING' ">
                                    分账中
                                </div>
                            </td>
                            <td>
                                <template v-if="sharing.editing">
                                    <span @click=" addSharingCancel(sharing) " class="black-cancel mr-20"></span>
                                    <span @click="confirmSyncSharing(sharing)" class="black-confirm"></span>
                                </template>
                                <template v-else-if="sharing.state === 'NONE' ">

                                    <span @click=" editSharing(sharing) " class="white-edit mr-20"></span>
                                    <span @click="deleteSharing(sharing)" class="white-delete"></span>

                                </template>
                            </td>
                        </tr>
                    </template>
                    <tr v-if="enabledEdit">
                        <td colspan="8">
                            <div class="new-sharing" @click="addSharing">
                                <span class="ns-btn"></span>
                                <span>添加分账</span>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="total-area">
                    <div class="item">
                        <span>总计分配比例</span>
                        <span>{{ totalInfo.per }}%</span>
                    </div>
                    <div class="item">
                        <span>分配金额</span>
                        <span>{{ totalInfo.amount }}</span>
                    </div>
                    <div class="item">
                        <span>剩余额度</span>
                        <span>{{ totalInfo.fzLost }}</span>
                    </div>
                    <div class="item">
                        <span>本单结余</span>
                        <span>{{ totalInfo.balance }}</span>
                    </div>
                    <div class="item">
                        <span>状态</span>
                        <span>{{ totalInfo.state }}</span>
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>

        <div class="foot-btns flex">
            <button @click=" $router.back() " class="btn btn-white">返回</button>
            <button v-if=" orderInfo.sharing === 1 " @click="orderFinish" class="btn">完结分账</button>
            
            <share-count-down-one type="one" :time="orderInfo.auto_sharing_time"></share-count-down-one>
        </div>

        <a-modal v-model="showSharePayModal" :footer="null" width="300px">
            <div class="text-center mb-4">使用微信扫码完成支付</div>
            <a-spin :spinning="sharePayQrcodeLoading">
                <div class="share-pay-image-box">
                    <img :src=" shareImage " alt="">
                </div>
            </a-spin>

            <div class="refresh-btn">
                <span v-if="sharePayQrcodeLoading">
                    <a-icon class="icon" type="loading"></a-icon>
                    <span>加载中，请稍候...</span>
                </span>
                <span v-else @click="fetchSharePayImage">
                    <a-icon class="icon" type="reload"></a-icon>
                    <span>点击刷新二维码</span>
                </span>
            </div>
        </a-modal>
    </div>
</template>

<script>
import api from "../../../repo/api";
import moment from "moment";
import AtInput from "../../../components/AtInput";
import Recommend from "./Com/Recommend";
import ShareCountDownOne from "@/views/AST/Cashier/Com/ShareCountDownOne.vue";

export default {
    name: "Sharing",
    components: {ShareCountDownOne, Recommend, AtInput},
    data() {
        return {
            routeMatch: '/ast/shop/cashier',
            orderId: '',
            enabledEdit: false,
            showSharePayModal: false,
            sharePayQrcodeLoading: true,
            shareOrderId: null,
            shareImage: null,
            timeOutFlag: null,
            orderInfo: {
                member: {},
                sales: [],
                parent: {},
                created_at: '',
                share_order: null,
                unlock_day: '',
                sharing_amount: 0,
                real_amount: 0,
                shares: [],
                excludes: [],
                yc_parents: [],
                ast_parents: []
            },
            offlinePayMethods: {
                bank_card: '银行卡支付',
                alipay: '支付宝支付',
                money: '现金支付',
                other: '其他方式'
            },
            memoHistory: [],
            excHistory: [],
            editSharingAmount: false,
            sharingAmount: 0,
            memberKeywords: '',
            memberList: [],
            memberListLoading: false,
            editExcludesList: {},
            editSharingList: {},
            totalInfo: {
                per: '0.00',
                amount: '0.00',
                balance: '0.00',
                state: '未分账',
                fzLost: '0.00',
            },
        }
    },
    mounted() {
        this.init()
        this.searchMember()
        this.fetMemoHistory()
        this.fetchExcHistory()
    },
    destroyed() {
        if (this.timeOutFlag) clearTimeout(this.timeOutFlag)
    },
    methods: {
        fetchExcHistory() {
            let his = localStorage['sharing_exc_histories']

            if (!his) return;
            this.excHistory = JSON.parse(his)
        },
        fetMemoHistory() {
            let his = localStorage['sharing_memo_histories']

            if (!his) return;
            this.memoHistory = JSON.parse(his)
        },
        delMemoHistrot(memo) {
            let idx = this.memoHistory.indexOf(memo);
            if (idx === -1) return;

            this.memoHistory.splice(idx, 1)

            localStorage['sharing_memo_histories'] = JSON.stringify(
                this.memoHistory
            )
        },
        delExcHistory(memo) {
            let idx = this.excHistory.indexOf(memo);
            if (idx === -1) return;

            this.excHistory.splice(idx, 1)

            localStorage['sharing_exc_histories'] = JSON.stringify(
                this.excHistory
            )
        },
        addMemoHistory(memo) {
            if (this.memoHistory.indexOf(memo) >= 0) return;
            this.memoHistory.push(memo)

            localStorage['sharing_memo_histories'] = JSON.stringify(
                this.memoHistory
            )
        },
        addExcHistory(memo) {
            if (this.excHistory.indexOf(memo) >= 0) return;
            this.excHistory.push(memo)

            localStorage['sharing_exc_histories'] = JSON.stringify(
                this.excHistory
            )
        },
        orderFinish() {

            let hasUnCreate = false;
            let hasUnSave = false;
            let hasUnSharing = false;

            this.orderInfo.shares.forEach(sharing => {

                if (!sharing.id) hasUnCreate = true
                if (sharing.editing) hasUnSave = true

                if (sharing.state === 'NONE') hasUnSharing = true
            })


            if (hasUnCreate || hasUnSave) {
                return this.$message.error('有未保存数据,请检查分账明细');
            }

            if (hasUnSharing) {
                return this.$message.error('有未处理数据,请检查分账明细');
            }

            this.$confirm({
                title: `确定要完结分账吗？`,
                content: '完结之后这笔订单不能再次分账。',
                onOk: () => {

                    this.$loading.show();

                    api.post('/ast-cashier/finish', {
                        id: this.orderInfo.id,
                    }, (data) => {

                        this.$loading.hide()

                        this.$message.data(data)

                        if (data.code === 0) {

                            this.$router.back()
                        }

                    });

                },
            });
        },
        selectMember(sharing, member) {

            sharing.to_member = member
            sharing.to_am_id = member.id

            this.$forceUpdate();
        },
        sharingShareScaleChanged(sharing) {

            let amount =  parseInt(this.orderInfo.sharing_amount * sharing.share_scale);
            
            sharing.share_amount = (amount / 100).toFixed(2)
            
        },
        searchMember() {
            this.memberListLoading = true

            api.get('/ast-member/members', {
                keywords: this.memberKeywords,
                order_id: this.orderId
            }, (data) => {

                this.memberListLoading = false
                let members = data.data.data;

                if (members.length > 5) members = members.slice(0, 5)

                this.memberList = members
            })

        },
        countTotalInfo() {
            let balance = this.orderInfo.sharing_amount
            let amount = 0, per = 0, state = '未分账', fz = 0

            this.orderInfo.shares.forEach(sharing => {

                if (!sharing.id) return

                balance -= sharing.share_amount
                per += (+sharing.share_scale)

                amount += (+sharing.share_amount)

                if (sharing.type === 'SHARING') {
                    fz += (+sharing.share_amount)
                }
            })

            if (balance <= 0) {
                state = '全分账'
            } else if (amount > 0) {
                state = '部分分账'
            }

            this.totalInfo.per = per.toFixed(2)
            this.totalInfo.amount = amount.toFixed(2)

            this.totalInfo.balance = parseFloat(balance).toFixed(2)
            this.totalInfo.state = state
            this.totalInfo.fzLost = (+this.orderInfo.real_amount * 0.3 - fz).toFixed(2)
        },
        editOrderSharingAmount() {
            this.sharingAmount = ''
            this.editSharingAmount = true
            this.sharingAmount = this.orderInfo.sharing_amount || 0
        },
        editExclude(exclude) {
            this.editExcludesList['a' + exclude.id] = JSON.parse(JSON.stringify(exclude))

            exclude.editing = true

            this.$forceUpdate()
        },
        editSharing(sharing) {

            this.editSharingList['a' + sharing.id] = JSON.parse(JSON.stringify(sharing))

            sharing.editing = true

            this.$forceUpdate()
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        init() {
            this.orderId = this.$route.params.id
            this.fetchDetail()
        },
        fetchDetail() {
            this.$loading.show()

            api.get('/ast-cashier/order-detail', {id: this.orderId}, (data) => {

                this.$loading.hide()

                if (data.code !== 0) {
                    this.$message.error('订单错误');
                    this.$router.back();
                }

                this.orderInfo = data.data

                this.enabledEdit = (+this.orderInfo.sharing) === 1;

                if (this.enabledEdit) {
                    this.enabledEdit = this.orderInfo.real_amount >= 0.1
                }

                this.countTotalInfo()
            })
        },
        addSharing() {

            if (!this.orderInfo.shares) this.orderInfo.shares = []
            this.orderInfo.shares.push({
                editing: true,
                type: 'SHARING',
                state: 'NONE',
                to_member: {}
            })
        },
        addExclude() {
            if (!this.orderInfo.excludes) this.orderInfo.excludes = []
            this.orderInfo.excludes.push({
                memo: "",
                amount: "",
                editing: true,
            })
        },
        addSharingCancel(item) {

            let idx = this.orderInfo.shares.indexOf(item)
            if (!item.id) {
                this.orderInfo.shares.splice(idx, 1)
                return
            }

            let history = this.editSharingList['a' + item.id]

            this.orderInfo.shares.splice(idx, 1, history)
        },
        addExcludeCancel(item) {

            let idx = this.orderInfo.excludes.indexOf(item)
            if (!item.id) {
                this.orderInfo.excludes.splice(idx, 1)
                return
            }

            let history = this.editExcludesList['a' + item.id]

            this.orderInfo.excludes.splice(idx, 1, history)
        },
        confirmSyncExclude(item) {
            if (!item.order_id) {
                item.order_id = this.orderId
            }

            setTimeout(() => {
                this.countTotalInfo()
            }, 2000)

            api.post('/ast-cashier/sync-exclude', item, (data) => {

                this.$message.data(data)

                if (data.code !== 0) return;

                let exclude = data.data

                item.id = exclude.id
                item.editing = false

                this.orderInfo.sharing_amount = exclude.sharing_amount

                this.addExcHistory(item.memo)

                this.$forceUpdate()

                this.countTotalInfo()
            });
        },
        confirmSyncSharing(item) {
            if (!item.order_id) {
                item.order_id = this.orderId
            }

            setTimeout(() => {
                this.countTotalInfo()
            }, 2000)

            api.post('/ast-cashier/sync-sharing', item, (data) => {

                this.$message.data(data)

                if (data.code !== 0) return;

                let order = data.data

                item.id = order.id
                item.editing = false

                this.addMemoHistory(item.memo)

                this.$forceUpdate()

                this.countTotalInfo()
            });
        },
        updateOrderMember(member) {
            this.$confirm({
                title: `确定要修改购买用户？`,
                onOk: () => {

                    this.$loading.show();

                    api.post('/ast-cashier/update-order-member', {
                        id: this.orderInfo.id,
                        member_id: member.id
                    }, (data) => {

                        this.$loading.hide()

                        this.$message.data(data)

                        if (data.code === 0) {
                            this.init()
                        }

                    });

                },
            });
        },
        confirmSharing(sharing) {

            let type = sharing.type === 'SHARING' ? '分账' : '记账';

            this.$confirm({
                title: `确定要完成${type}操作？`,
                onOk: () => {

                    this.$loading.show();

                    api.post('/ast-cashier/sharing', {
                        id: sharing.id,
                        order_id: sharing.order_id
                    }, (data) => {

                        this.$loading.hide()

                        this.$message.data(data)

                        if (data.code === 0) {
                            sharing.state = data.data.state

                            this.$forceUpdate()

                            this.countTotalInfo()
                        }

                    });

                },
            });

        },
        deleteSharing(sharing) {
            this.$confirm({
                title: `确定要删除这条记录？`,
                onOk: () => {

                    this.$loading.show();

                    api.post('/ast-cashier/delete-sharing', {
                        id: sharing.id,
                        order_id: sharing.order_id
                    }, (data) => {

                        this.$loading.hide()

                        this.$message.data(data)

                        if (data.code === 0) {

                            let idx = this.orderInfo.shares.indexOf(sharing)

                            this.orderInfo.shares.splice(idx, 1)

                            this.countTotalInfo()
                        }

                    });

                },
            });

        },
        deleteExclude(exclude) {
            this.$confirm({
                title: `确定要删除这条记录？`,
                onOk: () => {

                    this.$loading.show();

                    api.post('/ast-cashier/delete-exclude', {
                        id: exclude.id,
                        order_id: exclude.order_id
                    }, (data) => {

                        this.$loading.hide()

                        this.$message.data(data)

                        if (data.code === 0) {

                            let idx = this.orderInfo.excludes.indexOf(exclude)

                            this.orderInfo.excludes.splice(idx, 1)

                            this.orderInfo.sharing_amount = data.data.sharing_amount

                            this.countTotalInfo()
                        }

                    });

                },
            });

        },
        confirmEditSharingAmount() {

            this.$loading.show()

            api.post('/ast-cashier/update-sharing-amount', {
                id: this.orderId,
                amount: this.sharingAmount
            }, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {

                    this.orderInfo.sharing_amount = this.sharingAmount

                    this.editSharingAmount = false

                }
            })

        },
        activeSharePayModal() {
            this.showSharePayModal = true
            this.fetchSharePayImage()
        },
        fetchSharePayImage() {
            this.sharePayQrcodeLoading = true

            api.post('/ast-cashier-order/order-share-pay', {
                id: this.orderInfo.id
            }, data => {

                this.sharePayQrcodeLoading = false

                if (data.code !== 0) return this.$message.data(data)

                this.shareImage = data.data.image
                this.shareOrderId = data.data.id

                this.fetchShareOrderStatus()
            })
        },
        fetchShareOrderStatus() {
            let orderId = this.shareOrderId

            api.get('/ast-cashier-order/order-status', {
                id: orderId
            }, data => {

                if (data.data.status == 1) {

                    this.$message.success('分账支付成功');

                    setTimeout(() => {

                        location.reload()

                    }, 2000)

                    return;
                }

                if (orderId !== this.shareOrderId) return;

                this.timeOutFlag = setTimeout(() => {

                    this.fetchShareOrderStatus()

                }, 2000)


            })
        }
    }
}
</script>

<style lang="less" scoped>

.sharing-page {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
}

.line {
    width: 100%;
    border-bottom: 1px solid #EEEEEE;
}

.self-info {
    position: relative;

    .line {
        position: absolute;
        right: 0;
        width: calc(100% - 60px);
        top: 24px;
    }

    .white-edit-box {
        height: 16px;
        padding-right: 16px;
        background-color: white;
        position: relative;
        z-index: 2;
    }

    .member-card {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;


        .member-id, .member-phone, .member-name {
            z-index: 2;
            position: relative;
            background-color: white;
            padding-right: 48px;

            span {
                color: #999999;
                line-height: 22px;
                margin-right: 8px;
            }
        }

        img {
            width: 48px;
            height: 48px;
            margin-right: 12px;
        }
    }
}

.member-card {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;


    img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        border-radius: 50%;
    }

    .member-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}

.order-info-table {
    width: 100%;

    th {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        padding-top: 32px;
    }

    tr:first-child {
        th {
            padding-top: 0px;
        }
    }

    td {
        padding-top: 16px;
    }
}

.order-info-line {
    margin-top: 32px;
}

.sh-title {
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    background: #F5F5F5;
}

.sh-total {
    height: 40px;
    background: #F5F5F5;

    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;

    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: center;
}

.sh-opera, .new-sharing, .arrow-down-input {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: center;
}

.sh-total-title {
    margin-right: 8px;
}

.sh-total-money {
    color: #333;
}

.sh-opera {
    margin-left: 16px;
}

.ns-btn {
    background-image: url("/assets/icons/zx-btn-new.png");
    width: 16px;
    height: 16px;
    background-size: cover;
    margin-right: 10px;
}

.new-sharing {
    cursor: pointer;
    user-select: none;
}

.sharing-input {
    border: 0;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #333333;
    width: 50px;
}

.black-cancel, .black-confirm, .btn-edit, .white-edit, .white-delete {
    width: 16px;
    height: 16px;
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;

    &.small {
        width: 12px;
        height: 12px;
    }
}

.white-edit, .white-delete {
    border-radius: 0;
}

.white-edit {
    background-image: url("/assets/icons/input-edit.png");
}

.white-delete {
    background-image: url("/assets/icons/input-del.png");
}

.black-confirm {
    background-image: url("/assets/icons/stock-selected.png");
}

.black-cancel {
    background-image: url("/assets/icons/tips-modal-close.png");
    background-color: #DCDCDC;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
}

.mr-6 {
    margin-right: 6px;
}

.sh-list-table {
    width: 100%;

    td {
        height: 62px;
        text-align: center;

        padding: 16px;
        border: 1px solid #EEEEEE;
    }

    .member-card {
        justify-content: center;
    }
}

.new-sharing {

}

.arrow-down-input {
    position: relative;
    height: 40px;
    justify-content: space-around;


    &:after {
        content: ' ';
        display: block;
        width: 8px;
        height: 8px;
        background-image: url("/assets/icons/gj-icon.png");
        background-size: cover;
        position: absolute;
        right: 10px;
        top: 18px;
    }
}

.sh-select {
    width: 70px;

    /deep/ .ant-select-selection {
        border: 1px solid #333333;
        border-radius: 0;
        height: 40px;
        line-height: 40px;

        .ant-select-selection__rendered {
            line-height: 40px;
        }

        .ant-select-arrow {
            display: none;
        }
    }
}

.mr-20 {
    margin-right: 20px;
}

.sh-editing-row {
    td:nth-child(1) {
        width: 185px;
    }

    td:nth-child(3) {
        min-width: 175px;
    }

    td:nth-child(4) {
        width: 100px;
    }

    td:nth-child(5) {
        width: 145px;
    }
}

.search-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #333333;
    background-image: url("/assets/icons/btn-search.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
}

.member-item {
    margin-top: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    height: 32px;
    cursor: pointer;

    &:hover {
        background: #F5F5F5;
    }
}

.total-area {
    display: flex;
    align-items: center;
    align-content: center;
    height: 65px;

    .item {
        width: 25%;
        text-align: center;

        span:first-child {
            margin-right: 9px;
        }
    }
}

.foot-btns {
    margin-top: 32px;
    margin-bottom: 32px;

    .btn {
        margin-right: 16px;
    }
}

.sharing-page {
    width: 1008px;
}

.sh-info {
    margin-top: 32px;
}

.sharing-card {
    margin-top: 32px;

    /deep/ .ant-card-head-title {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        position: relative;
        overflow: unset;

        &:before {
            content: ' ';
            width: 2px;
            height: 14px;
            background: #333333;
            display: block;
            position: absolute;
            left: -24px;
        }

    }
}


.sh-auto-complete {
    /deep/ .ant-input {
        height: 40px;
        border-radius: 0;
        border-color: #333333;
    }
}

.admin-list {
    display: flex;
    justify-content: flex-start;

    .member-card {
        margin-right: 16px;
    }
}

.memo-his-item {
    position: relative;

    .del-btn {
        display: block;
        width: 14px;
        height: 14px;
        background-image: url("/assets/icons/tips-modal-close.png");
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 5px;
        right: 0;
        background-color: #DCDCDC;
        border-radius: 50%;
        opacity: 0;
    }

    &:hover .del-btn {
        opacity: 1;
    }
}


.exclude-item {
    display: flex;
    align-items: center;
    justify-content: center;

    div:first-child {
        margin-right: 88px;
    }

    .sharing-input {
        width: 80px;
    }
}

.sh-total {
    > div:first-child {
        margin-right: 86px;
    }
}

.exclude-complete {
    width: 80px;
    border: none;

    /deep/ .ant-input {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #333333 !important;
        border-radius: 0;
        padding: 0;
        line-height: 18px;
        height: 18px;
    }
}

.first-order {
    position: absolute;
    width: 96px;
    height: 84px;
    right: 0;
    top: 5px;
    background-image: url("/assets/icons/first-order.png");
    background-size: cover;
}

.share-pay-image-box {
    width: 200px;
    height: 200px;
    background-color: #999;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.refresh-btn {
    margin-top: 16px;
    text-align: center;
    user-select: none;

    span {
        cursor: pointer;
    }

    .icon {
        margin-right: 4px;
    }
}
</style>